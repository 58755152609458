import { getAction, } from '@/api/common/request'
const baseURL = 'xun/work/projectView'

const projectViewListApi=(params)=>getAction(baseURL+'/list',params);

const projectViewGetPersonViewApi=(params)=>getAction(baseURL+'/getPersonViewData',params);


export{
    projectViewListApi,
    projectViewGetPersonViewApi,
}
