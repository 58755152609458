<template>
  <div>
    <div>
      <el-button @click="tripRutren">退回</el-button>
      <el-button @click="click">全屏</el-button>
      <el-button v-if="this.calendarTypeSwitch" @click="pushData"
        >上报数据</el-button
      >
    </div>
    <div>
      <div style="margin: 20px 20px 0px 20px">
        <el-form layout="inline" label-position="top">
          <el-row :gutter="24">
            <el-col :md="3" :sm="3" v-if="this.calendarTypeSwitch == false">
              <el-form-item label="类型" prop="type">
                <el-select
                  v-model="queryParams.type"
                  placeholder="请选择"
                  @change="getTypeButton"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="3" :sm="3" v-if="this.calendarTypeSwitch == true">
              <el-form-item label="视图类型" prop="viewType">
                <el-select
                  v-model="queryParams.viewType"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in viewTypeHistryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :md="3"
              :sm="3"
              v-if="
                this.calendarTypeSwitch == true &&
                this.queryParams.viewType == 'list'
              "
            >
              <el-form-item label="月份" prop="month">
                <el-date-picker
                  clearable
                  v-model="queryParams.month"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="选择月"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col
              :md="3"
              :sm="3"
              v-if="
                this.queryParams.type == 'days' &&
                this.calendarTypeSwitch == false
              "
            >
              <el-form-item label="视图类型" prop="viewType">
                <el-select
                  v-model="queryParams.viewType"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in viewTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :md="3"
              :sm="3"
              v-if="
                this.queryParams.viewType == 'list' &&
                this.queryParams.type == 'days' &&
                this.calendarTypeSwitch == false
              "
            >
              <el-form-item label="是否按月切分" prop="monthGroup">
                <el-select
                  v-model="queryParams.monthGroup"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in monthGroupList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :md="3"
              :sm="3"
              v-if="
                this.queryParams.viewType == 'graph' &&
                this.queryParams.type == 'days' &&
                this.calendarTypeSwitch == false
              "
            >
              <el-form-item label="是否聚合展示" prop="isPolymerize">
                <el-select
                  clearable
                  v-model="queryParams.isPolymerize"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in monthGroupList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col
              :md="3"
              :sm="3"
              v-if="
                this.queryParams.type == 'days' &&
                this.calendarTypeSwitch == false
              "
            >
              <el-form-item label="关系类型" prop="memberType">
                <el-select
                  clearable
                  v-model="queryParams.memberType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in memberTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col
              :md="3"
              :sm="3"
              v-if="
                this.queryParams.viewType == 'graph' &&
                this.queryParams.type == 'days' &&
                this.queryParams.isPolymerize == true &&
                this.calendarTypeSwitch == false
              "
            >
              <el-form-item label="是否显示总数" prop="isSum">
                <el-select
                  v-model="queryParams.isSum"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in monthGroupList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="3" :sm="3">
              <el-button
                style="margin: 50px 0px 0px 0px"
                @click="listplanButton"
                >查询</el-button
              >
              <el-button
                v-if="this.queryParams.viewType == 'list'"
                style="margin: 50px 0px 0px 0px"
                @click="downExcleData"
                >下载数据</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 列表或者数据 -->
      <div
        v-if="this.calendarTypeSwitch == false && this.viewGraphShow == true"
      >
        <h2 v-if="this.queryParams.isSum == true && this.sumData != null">
          总人天:{{ this.sumData }}
        </h2>
        <div
          id="myLifeInfoDegrees"
          class="myLifeInfoDegrees"
          ref="myLifeInfoDegrees"
        ></div>
      </div>
      <div
        v-if="this.calendarTypeSwitch == false && this.viewGraphShow == false"
      >
        <el-table :data="dataColumnList" show-summary="">
          <el-table-column label="序号" type="index" align="center" />
          <el-table-column label="名称" align="center" prop="memberSignName" />
          <el-table-column label="月份" align="center" prop="monthsFor" />
          <el-table-column label="开始时间" align="center" prop="startTime" />
          <el-table-column label="结束时间" align="center" prop="endTime" />
          <el-table-column label="花费人天" align="center" prop="days" />
          <el-table-column
            label="花费费用"
            align="center"
            prop="realityMoney"
          />
          <el-table-column label="备注" align="center" prop="describes" />
        </el-table>
      </div>
    </div>

    <!-- 日历视图表 -->
    <div
      v-if="this.calendarTypeSwitch && this.queryParams.viewType == 'calendar'"
    >
      <span style="margin: 5px 10px">{{ noweDataFrom }}</span>
      <el-calendar style="margin: 5px 10px" v-model="nowdate">
        <template slot="dateCell" slot-scope="{ date, data }">
          <p :class="data.isSelected ? 'is-selected' : ''">
            {{ data.day.slice(-2) }} {{ data.isSelected ? "✔️" : "" }}
          </p>
          <div v-for="(i, index) in dayList" :key="index">
            <div v-if="data.day == i.timesFor" style="margin: -20px -10px">
              {{ i.minTimeHoursFor }}-{{ i.maxTimeHoursFor }}
              <el-button type="warning" size="mini" circle>{{
                i.duration
              }}</el-button>
            </div>
          </div>
        </template>
      </el-calendar>
    </div>
    <div v-if="this.calendarTypeSwitch && this.queryParams.viewType == 'list'">
      <el-table :data="dataHistryColumnList" show-summary="">
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column label="时间" align="center" prop="timesFor" />
        <el-table-column
          label="开始时间"
          align="center"
          prop="minTimeHoursFor"
        />
        <el-table-column
          label="结束时间"
          align="center"
          prop="maxTimeHoursFor"
        />
        <el-table-column label="花费时长" align="center" prop="duration" />
        <el-table-column label="备注" align="center" prop="describes" />
      </el-table>
    </div>
    <el-dialog
      title="上报数据"
      :before-close="handleClose"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="30%"
      :visible.sync="dialog"
    >
      <el-form :model="addEditForm" ref="addEditForm" label-width="80px">
        <el-row :gutter="24">
          <el-form-item label="时间" prop="times">
            <el-date-picker
              v-model="addEditForm.times"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              type="datetime"
              placeholder="选择时间"
            ></el-date-picker>
          </el-form-item>
        </el-row>
        <el-row :gutter="24">
          <el-form-item label="类型" prop="type">
            <el-radio-group v-model="addEditForm.type" prop="type">
              <el-radio label="1" border>上班</el-radio>
              <el-radio label="2" border>下班</el-radio>
              <el-radio label="3" border>其他</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row :gutter="24">
          <el-form-item label="名称" prop="name">
            <el-input placeholder="请输入名称" v-model="addEditForm.name" />
          </el-form-item>
        </el-row>
        <el-row :gutter="24">
          <el-form-item label="上报人" prop="reportName">
            <el-input placeholder="上报人" v-model="addEditForm.reportName" />
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="directButton()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { pushDataTaskApi } from "@/api/work/project/reimburse";
import { getAllMonthsNoCurrent } from "@/utils/timeUtils";
import screenfull from "screenfull";
import format from "@/plugins/format.js";
import {
  projectViewListApi,
  projectViewGetPersonViewApi,
} from "@/api/work/project/dataView.js";
export default {
  data() {
    return {
      dialog: false,
      addEditForm: {},
      dataHistryColumnList: [],
      dataColumnList: [],
      workEcarts: null,
      nowdate: new Date(),
      type: 1,
      queryParams: {
        recodeMonth: new Date(),
        currentPage: 1,
        pageSize: 10,
        total: 0,
        type: "days",
        viewType: "graph",
        isPolymerize: true,
        monthGroup: true,
        isSum: false,
        memberType:null,
        month: this.dayjs(this.dayjs()).format("YYYY-MM"),
      },
      dayList: [],
      monthss: "",
      noweDataFrom: format(new Date(), "YYYY-MM"),

      typeList: [
        {
          value: "money",
          label: "费用",
        },
        {
          value: "days",
          label: "人天",
        },
      ],
      memberTypeList:[  {
          key: 1,
          value: 1,
          label: "正岗",
        },
        {
          key: 2,
          value: 2,
          label: "正岗-外包",
        },
        {
          key: 3,
          value: 3,
          label: "项目-外包",
        },],
      viewTypeHistryList: [
        {
          value: "list",
          label: "列表",
        },
        {
          value: "calendar",
          label: "日历",
        },
      ],
      viewTypeList: [
        {
          value: "list",
          label: "列表",
        },
        {
          value: "graph",
          label: "视图",
        },
      ],
      monthGroupList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      viewTitle: "",
      projectStartTime: null,
      projectEndTime: null,
      sumData: null,
      viewGraphShow: false,
      // 日历类型开关
      calendarTypeSwitch: true,
      projectCodes:null
    };
  },
  watch: {
    nowdate() {
      var year = this.nowdate.getFullYear();
      var month = this.nowdate.getMonth() + 1;
      var date = this.nowdate.getDate();
      if (date >= 1 && date <= 9) {
        //日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {
        //月如果小于10就补个0
        month = "0" + month;
      }
      //这个时间是变了的
      console.log(this.nowdate);
      this.noweDataFrom = format(this.nowdate, "YYYY-MM");
      if (this.monthss == null) {
        console.log("newx2");
        this.listplanButton();
        this.monthss = this.noweDataFrom;
      } else {
        if (this.monthss != this.noweDataFrom) {
          this.monthss = this.noweDataFrom;
          this.listplanButton();
        }
      }
      this.pickData = format(this.nowdate, "YYYY-MM-DD");
    },
    "queryParams.currentPage"(val) {
      this.listplanButton();
    },
    "queryParams.pageSize"(val) {
      this.listplanButton();
    },
  },
  computed: {
    monthValue: {
      get: function () {
        return this.nowdate;
      },
      set: function (newValue) {
        this.nowdate = newValue;
      },
    },
  },
  created() {
    this.listplanButton();
  },
  methods: {
    show(row, type) {
      if (type == 1) {
        this.queryParams.viewType = "list";
        this.calendarTypeSwitch = true;
      } else if (type == 2) {
        this.calendarTypeSwitch = false;
      }
      this.type = type;
      this.projectStartTime = row.startTime;
      this.projectEndTime = row.endTime;
      this.queryParams.projectCode = row.code;
      this.projectCodes=row.code
      this.listplanButton();
    },
    tripRutren() {
        this.projectCodes=null
      this.viewGraphShow = false;
      this.$parent.dataViewShow = true;
      this.calendarTypeSwitch = false;
      this.queryParams.viewType = "list";
      this.moneyView = true;
      this.queryParams.projectCode = null;
      this.dataHistryColumnList = [];
      if (this.workEcarts) {
        this.workEcarts.dispose();
        this.workEcarts = null;
      }
      this.$parent.dataViewsWorkInfoShow = !this.$parent.dataViewsWorkInfoShow;
    },

    listplanButton() {
      console.log(this.queryParams);
      if (this.type == 1) {
        var queryMonth = this.noweDataFrom;
        if (this.queryParams.viewType == "list") {
          queryMonth = this.queryParams.month;
        }
        console.log(this.projectCodes)
        projectViewListApi({
          month: queryMonth,
          projectCode: this.projectCodes,
        }).then((res) => {
          this.dayList = res.result;
          if (this.queryParams.viewType == "list") {
            console.log(res.result);
            this.dataHistryColumnList = res.result;
          }
        });
      }
      if (this.type == 2) {
        if (this.queryParams.type == "money") {
          this.viewTitle = "费用视图";
          this.queryParams.viewType = "list";
        }
        if (this.queryParams.type == "days") {
          this.viewTitle = "人天视图";
        }
        if (this.queryParams.viewType == "graph") {
          this.viewGraphShow = true;
        } else {
          this.viewGraphShow = false;
        }
        if (this.workEcarts) {
          this.workEcarts.dispose();
          this.workEcarts = null;
        }
        if(this.queryParams.memberType==null||this.queryParams.memberType==''){

        }
        projectViewGetPersonViewApi(this.queryParams).then((res) => {
          if (this.queryParams.viewType == "graph") {
            this.drawLine(res.result);
          } else {
            this.dataColumnList = res.result;
          }
        });
      }
    },

    click() {
      // 使用screenfull.enabled方法判断是否支持screenfull
      // 若不允许进入全屏，发出不允许提示
      if (!screenfull.isEnabled) {
        this.$message({
          message: "该浏览器不支持全屏",
          type: "warning",
        });
        return false;
      }
      // 调用 screenfull.toggle() 切换全屏与非全屏
      screenfull.toggle();
    },

    change() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    // 注册事件
    screenFullInit() {
      if (screenfull.enabled) {
        screenfull.on("change", this.change);
      }
    },
    // 移除注册的事件
    destroy() {
      if (screenfull.enabled) {
        screenfull.off("change", this.change);
      }
    },
    getTypeButton(val) {
      console.log(val);
    },
    drawLine(rowList) {
      //获取全部的名字
      //构造新的数据
      const dataList = rowList.map((item) => ({
        ...item,
        type: "bar",
        stack: this.queryParams.isPolymerize ? "total" : this.memberSignName,
        label: { show: true },
        emphasis: { focus: "series" },
        data: item.daysAll,
        name: item.memberSignName,
      }));
      if (this.queryParams.isSum) {
        const summData = {
          daysAll: rowList
            .reduce((reslut, obj) => {
              obj.daysAll.forEach((num, index) => {
                reslut[index] = (reslut[index] || 0) + num;
              });
              return reslut;
            }, [])
            .map((sum) => sum || 0),
        };
        this.sumData = summData.daysAll.reduce((sum, num) => sum + num, 0);
        const sumDataReslut = {
          name: "sum",
          type: "bar",
          barGap: "-100%",
          stack: "total",
          data: summData.daysAll,
          label: {
            normal: {
              show: true,
              position: "top",
              formatter: "{c}",
              textStyle: { color: "#000" },
            },
          },
          itemStyle: {
            normal: {
              color: "rgba(128, 128, 128, 0)",
            },
          },
          emphasis: { focus: "series" },
        };
        dataList.push(sumDataReslut);
        console.log("data:{}", sumDataReslut);
      }

      const timeList = getAllMonthsNoCurrent(
        this.projectStartTime,
        this.projectEndTime
      );
      // 基于准备好的dom，初始化echarts实例
      let myChartLife = this.$echarts.init(this.$refs.myLifeInfoDegrees);
      // 绘制图表
      myChartLife.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: timeList,
        },
        yAxis: {
          type: "value",
        },
        series: dataList,
      });
      this.workEcarts = myChartLife;
    },

    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.addEditForm = {};
      this.dialog = false;
      this.listplanButton();
    },
    pushData() {
      this.dialog = true;
    },
    directButton() {
      this.addEditForm.projectCode = this.queryParams.projectCode;
      pushDataTaskApi(this.addEditForm).then((res) => {
        this.cancelForm();
      });
    },
    downExcleData() {
      let list = [];
      if(this.calendarTypeSwitch){
        list=this.dataHistryColumnList
      }else{
        list=this.dataColumnList
      }

      // 获取所有对象的字段名
      const fieldNames = Array.from(
        new Set(list.flatMap((obj) => Object.keys(obj)))
      );

      // 创建一个二维数组，用于存储列表数据
      const data = list.map((obj) =>
        fieldNames.map((fieldName) => obj[fieldName] || "")
      );

      // 创建Excel文件内容
      let content = "data:text/csv;charset=utf-8,";

      // 添加表头
      const header = fieldNames.map((fieldName) => `"${fieldName}"`).join(",");
      content += header + "\r\n";

      // 添加数据行
      data.forEach((row) => {
        const rowData = row.map((cell) => `"${cell}"`).join(",");
        content += rowData + "\r\n";
      });

      // 创建Blob对象，并生成下载链接
      const blob = new Blob([content], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      // 创建一个隐藏的<a>元素，设置下载链接，并模拟点击下载
      const link = document.createElement("a");
      link.href = url;
      link.download = "data.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();

      // 清理下载链接
      URL.revokeObjectURL(url);
    },
  },
};
</script>
<style lang="less" scoped>
#myLifeInfoDegrees {
  width: 90%;
  height: 600px;
  margin: 30px 20px;
  background-color: #ffffff00;
  float: left;
}
#myLifeInfoFree {
  width: 45%;
  height: 600px;
  margin: 30px 20px;
  background-color: #ffffff00;
  float: right;
}
</style>